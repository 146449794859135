import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {},
    game: false,
    uid: false,
    campaign: false,
    view: '',
    choice: false
}

export const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        setGame: (state, action) => {
            state.game = action.payload
        },
        setCampaign: (state, action) => {
            state.campaign = action.payload
        },
        setView: (state, action) => {
            state.view = action.payload
        },
        setPlayerData: (state, action) => {
            state.data = action.payload
        },
        setPlayerChoice: (state, action) => {
            state.choice = action.payload
        },
        setPlayerUid:  (state, action) => {
            state.uid = action.payload
        },
        reset: (state) => initialState,
    },
})

// Action creators are generated for each case reducer function
export const {
    setGame,
    setCampaign,
    setView,
    setPlayerData,
    setPlayerUid,
    setPlayerChoice,
    reset
} = playerSlice.actions

export default playerSlice.reducer