// https://mui.com/customization/typography/

const typography = {
    fontFamily: [
        'CustomFont', 'Open Sans',
    ].join(','),
    fontSize: '6vmin',
    fontWeight: 400,
    lineHeight: '14px',
    h1: {
        fontSize: '10vmin',
        fontWeight: 400,
        textTransform: 'uppercase'
    },
    h2: {
        fontSize: '8vmin',
        fontWeight: 400,
        textTransform: 'uppercase'
    },
    bigText: {
        lineHeight: '8vmin',
        fontSize: '12vmin',
        fontWeight: 400,
    },
    small: {
        lineHeight: '3vmin',
        fontSize: '4vmin',
        fontWeight: 400,
        a: {
            color: 'white',
            textDecoration: 'none',
            '&:hover' : {
                color: '#3863cc',
            },
        },
    },
    xSmall: {
        fontSize: '2vmin',
        fontWeight: 400,
    }
}

export default typography
