const fontSizes = [
    12, // xxxs
    14, // xxs
    16, // xs, body
    18, // sm
    20, // md, h3
    24, // lg, h2
    26, // xl
    28, // xxl
    30, // xxxl
    32, // xxxxl, h1
]

fontSizes.xxxs = fontSizes[0]
fontSizes.xxs = fontSizes[1]
fontSizes.xs = fontSizes[2]
fontSizes.sm = fontSizes[3]
fontSizes.md = fontSizes[4]
fontSizes.lg = fontSizes[5]
fontSizes.xl = fontSizes[6]
fontSizes.xxl = fontSizes[7]
fontSizes.xxxl = fontSizes[8]
fontSizes.xxxxl = fontSizes[9]

fontSizes.body = fontSizes[2]
fontSizes.h1 = fontSizes[9]
fontSizes.h2 = fontSizes[5]
fontSizes.h3 = fontSizes[4]

export default fontSizes
